import type { Consumption, FlowId, SupplyAddress } from '@essent/new-customer';
import { EnergyType, ProductDuration } from '@essent/new-customer';
import type {
  ConsumptionValuesVM,
  CostPerUnitVM,
  DurationFilter,
  DurationViewModel,
  ElectricityConsumptionValuesVM,
  EnergyTypeDropdownVM,
  GetOfferDuration,
  GetOffersPayload,
  Offer,
  OfferOverview,
} from '@innogy/become-a-customer/shared/interfaces';
import camelCase from 'lodash/camelCase';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';

import {
  calculateTariffForDropDownVM,
  calculateTariffPriceForElectricity,
  calculateTariffPriceForGas,
} from './summer-winter-calculation';

export function getDurationViewModel(
  offer: Offer,
  durations: GetOfferDuration[]
): DurationViewModel {
  const duration = durations.find(
    (_duration) => _duration.duration === offer.duration
  );

  return {
    durationTitle: duration?.durationTitle,
    duration: duration?.duration,
    offerId: offer.offerId,
  };
}

export function getEnergyTypeDropdownVM(
  offerOverview: OfferOverview,
  tariffZone: string
): EnergyTypeDropdownVM {
  const consumptionPrices = offerOverview?.consumptionPrices ?? [];
  const prices = offerOverview?.priceGroups?.[0]?.prices ?? [];
  const fixedDeliveryCosts =
    prices?.find((price) => price.id === '5') ?? undefined;
  const fixedRedeliveryCosts =
    prices?.find((price) => price.id === '12') ?? undefined;

  return {
    fixedDeliveryCosts: fixedDeliveryCosts?.unitPrice,
    fixedRedeliveryCosts: fixedRedeliveryCosts?.unitPrice,
    fixedRedeliveryCostsScale: getRedeliveryScale(
      fixedRedeliveryCosts?.description
    ),
    tariffPeriods: calculateTariffForDropDownVM(consumptionPrices, tariffZone),
  };
}

export function getConsumptionValuesEnergyTypes(
  consumptionState?: Consumption[]
) {
  if (consumptionState == null) {
    return undefined;
  }

  return [
    ...new Set(consumptionState.map((consumption) => consumption.energyType)),
  ];
}

export function getConsumptionValuesViewModel(
  consumptionState?: Consumption[]
) {
  if (consumptionState == null) {
    return undefined;
  }

  const initialConsumptionValues: ConsumptionValuesVM = {
    electricity: {
      supplyNormal: 0,
      supplyLow: 0,
      returnSupplyNormal: 0,
      returnSupplyLow: 0,
      returnSupplyTotal: 0,
    },
    gas: 0,
  };

  return consumptionState.reduce((consumptionValues, consumption) => {
    if (consumption.energyType === EnergyType.ELECTRICITY) {
      consumptionValues.electricity = getElectricityConsumptionValues(
        consumption,
        consumptionValues
      );
    }
    if (consumption.energyType === EnergyType.GAS) {
      consumptionValues.gas = consumption.standardAnnualUsages[0]?.reading || 0;
    }
    return consumptionValues;
  }, initialConsumptionValues);
}

export const getOfferCostsPerUnit = (offer?: Offer): CostPerUnitVM => {
  const initialCostPerUnitValues: CostPerUnitVM = {};

  if (!offer?.commodities) {
    return initialCostPerUnitValues;
  }

  return offer.commodities.reduce((costPerUnitValues, commodity) => {
    const offerOverview = offer.offerOverviews.find(
      (value) => value.energyType === commodity
    );
    const prices = offerOverview?.priceGroups[0]?.prices ?? [];
    const fixedDeliveryCosts = prices.find((price) => price.id === '5');
    const fixedRedeliveryCosts = prices.find((price) => price.id === '12');

    if (commodity === EnergyType.ELECTRICITY) {
      costPerUnitValues.electricity = {
        fixedRedeliveryCostsScale: getRedeliveryScale(
          fixedRedeliveryCosts?.description
        ),
        fixedRedeliveryDailyCosts: fixedRedeliveryCosts?.unitPrice,
        fixedRedeliveryPeriodAmount: fixedRedeliveryCosts?.expectedPeriodAmount,
        fixedDeliveryDailyCosts: fixedDeliveryCosts?.unitPrice,
        fixedDeliveryPeriodAmount: fixedDeliveryCosts?.expectedPeriodAmount,
        tariffPeriods: offerOverview?.consumptionPrices
          ? calculateTariffPriceForElectricity(offerOverview?.consumptionPrices)
          : [],
      };
    }

    if (commodity === EnergyType.GAS) {
      costPerUnitValues.gas = {
        fixedDeliveryDailyCosts: fixedDeliveryCosts?.unitPrice,
        fixedDeliveryPeriodAmount: fixedDeliveryCosts?.expectedPeriodAmount,
        tariffPeriods: offerOverview?.consumptionPrices
          ? calculateTariffPriceForGas(offerOverview?.consumptionPrices)
          : [],
      };
    }

    return costPerUnitValues;
  }, initialCostPerUnitValues);
};

function getElectricityConsumptionValues(
  consumption: Consumption,
  consumptionValues: ConsumptionValuesVM
) {
  const electricityConsumptionValue = consumption.standardAnnualUsages.reduce(
    (electricityConsumptionValues, register) => {
      const electricityConsumptionKey = camelCase(
        register.directionTariff
      ) as keyof ElectricityConsumptionValuesVM;

      electricityConsumptionValues[electricityConsumptionKey] =
        register.reading || 0;

      return electricityConsumptionValues;
    },
    consumptionValues.electricity
  );
  electricityConsumptionValue.returnSupplyTotal =
    electricityConsumptionValue.returnSupplyNormal +
    electricityConsumptionValue.returnSupplyLow;

  return electricityConsumptionValue;
}

export function generateGetOfferPayload(payload: {
  flowId: string;
  offerSet: string;
  address: SupplyAddress;
  consumption: ConsumptionValuesVM;
  isCustomer?: boolean;
  durationFilter: DurationFilter;
}): GetOffersPayload {
  return {
    flowId: payload.flowId as unknown as FlowId,
    offerSet: payload.offerSet,
    customerSegment: payload.address.customerSegment,
    postcode: payload.address.postcode,
    houseNumber: payload.address.houseNumber,
    houseNumberExtension: payload.address.houseNumberExtension ?? '',
    electricity: payload.consumption.electricity.supplyNormal,
    electricity_low: payload.consumption.electricity.supplyLow,
    electricity_return: payload.consumption.electricity.returnSupplyNormal,
    gas: payload.consumption.gas,
    durationFilter: payload.durationFilter,
    ...(payload.isCustomer !== undefined && { isCustomer: payload.isCustomer }),
  };
}

export function offersAreEqual(offerA: Offer, offerB: Offer) {
  return (
    offerA.campaignId === offerB.campaignId &&
    offerA.duration === offerB.duration &&
    offerA.incentiveId === offerB.incentiveId
  );
}

export function isMonoOffer(offer: Offer) {
  return offer.commodities.length == 1;
}

export function getYearlyCostsFields(
  rendering?: InnogyComponentRendering
): [string, string, string, string, string, string] {
  return [
    getFieldValue(
      rendering?.fields,
      'YearlyCostsTotalDynamicWithIncentiveLabel',
      ''
    ),
    getFieldValue(
      rendering?.fields,
      'YearlyCostsTotalDynamicWithoutIncentiveLabel',
      ''
    ),
    getFieldValue(rendering?.fields, 'YearlyCostsTotalsWithIncentive', ''),
    getFieldValue(rendering?.fields, 'YearlyCostsTotals', ''),
    getFieldValue(
      rendering?.fields,
      'YearlyCostsTotalsMultiYearWithIncentive',
      ''
    ),
    getFieldValue(rendering?.fields, 'YearlyCostsTotalsMultiYear', ''),
  ];
}

export function getOrderOverviewFields(
  rendering?: InnogyComponentRendering
): [string, string, string, string, string, string] {
  return [
    getFieldValue(rendering?.fields, 'CostsDynamicLabelWithIncentive', ''),
    getFieldValue(rendering?.fields, 'CostsDynamicLabel', ''),
    getFieldValue(rendering?.fields, 'CostsPerYearLabelWithIncentive', ''),
    getFieldValue(rendering?.fields, 'CostsPerYearLabel', ''),
    getFieldValue(rendering?.fields, 'CostsMultiYearLabelWithIncentive', ''),
    getFieldValue(rendering?.fields, 'CostsMultiYearLabel', ''),
  ];
}

export function getYearlyCostsLabel(
  dynamicWithIncentiveLabel: string,
  dynamicWithoutIncentiveLabel: string,
  singleYearWithIncentiveLabel: string,
  singleYearWithoutIncentiveLabel: string,
  multiYearWithIncentiveLabel: string,
  multiYearWithoutIncentiveLabel: string,
  offer?: Offer
) {
  if (offer) {
    const hasOneOrFlexDuration =
      offer.duration === ProductDuration.ONE ||
      offer.duration === ProductDuration.FLEX;

    const hasIncentive = offer.incentiveId;

    if (offer.isDynamicProduct) {
      return hasIncentive
        ? dynamicWithIncentiveLabel
        : dynamicWithoutIncentiveLabel;
    }

    if (hasOneOrFlexDuration) {
      return hasIncentive
        ? singleYearWithIncentiveLabel
        : singleYearWithoutIncentiveLabel;
    }

    return hasIncentive
      ? multiYearWithIncentiveLabel
      : multiYearWithoutIncentiveLabel;
  } else {
    return undefined;
  }
}

/**
 * Given the description of the redelivery costs, extract the scale from it on which the redelivery costs
 * are based. Example of the description: Terugleveringskosten 1.001 t/m 1.250 kWh (€ 2.00 /maand)
 */
export function getRedeliveryScale(description?: string): string {
  if (!description) {
    return '';
  }
  const regex = new RegExp(/(?:>\s*)?\d.*kWh/i);
  return regex.exec(description)?.[0] ?? '';
}
