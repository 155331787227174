import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  RequestClientInjectionToken,
  getRequestClient,
} from '@essent/ngrx-service-wrapper';
import { API_PREFIX, ConfigModule } from '@innogy/core-config-angular';
import createClient from 'openapi-fetch';
import { HttpClient } from '@angular/common/http';

import { API_CLIENT } from './injection-tokens';

const providers = [
  {
    provide: API_CLIENT,
    useFactory: (baseUrl: string) =>
      createClient({
        baseUrl,
        headers: {
          Accept: 'application/json',
        },
      }),
    deps: [API_PREFIX],
  },
  {
    provide: RequestClientInjectionToken,
    useFactory: getRequestClient,
    deps: [HttpClient, API_PREFIX],
  },
];

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers,
})
export class ApiClientModule {}
